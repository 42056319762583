export type AuthToken = {
  token: string;
  expiresAt?: Date;
};

export type NamedAuthToken = AuthToken & { name: string };

export type B2CTokenRequestDto = { b2cTokenRequest: boolean };

export type B2CAccessTokenRequestDto = { b2cAccessTokenRequest: boolean; scope: string[] };

export const B2C_SERVICE_TOKEN_REQUEST_DTO: B2CTokenRequestDto = { b2cTokenRequest: true };

export const B2C_ACCESS_TOKEN_REQUEST_DTO: B2CAccessTokenRequestDto = { b2cAccessTokenRequest: true, scope: [] };

export type ServiceTokenRequestDto = {
  clientId: string;
  orgId: string;
};

export type TokenRequestDto = ServiceTokenRequestDto | B2CTokenRequestDto | B2CAccessTokenRequestDto;

export function isB2CTokenRequestDto(dto: TokenRequestDto): dto is B2CTokenRequestDto {
  return (dto as B2CTokenRequestDto)?.b2cTokenRequest === true;
}

export function isB2CAccessTokenRequestDto(dto: TokenRequestDto): dto is B2CAccessTokenRequestDto {
  return (dto as B2CAccessTokenRequestDto)?.b2cAccessTokenRequest === true;
}
